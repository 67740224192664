.dropZone {
    margin-top: 15px;
    min-height: 100px;
    border: 2px dashed #1976d2;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 15px 10px;
    transition: all .2s ease-in-out;
}
.dropZone.not-title::after {
    content: '';
}
.dropZone::before {
    content: attr(data-label);
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: white;
    padding: 0px 5px;
    color: #1976d2;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.dropZone::after {
    content: "Выберите или перетащите файлы в эту область";
    position: absolute;
    line-height: 1.2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1976d2;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.dropZone input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0px;
    right: 0px;
    z-index: 1;
}
.dropZone .dropZone-image {
    position: relative;
    display: flex;
    max-width: 100px;
    max-height: 100px;
    align-items: center;
    justify-content: center;
}
.dropZone .dropZone-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #1976d2;
    border-radius: 10px;
}
.dropZone .dropZone-image .remove {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(80%, -50%);
    z-index: 2;
}
.dropZone.dragActive {
    border-color: #3ed219;
}
.dropZone.dragActive::before,
.dropZone.dragActive::after {
    color: #3ed219;
}