/* Basic editor styles */
.ProseMirror {
    padding: 5px 20px;
    border: 1px solid #1976d2;
    border-radius: 5px;
    > * + * {
      margin-top: 0.75em;
    }
  
    ul,
    ol {
      padding: 0 1rem;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }
  
    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }
  
    pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
  
      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  
    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0D0D0D, 0.1);
    }
  
    hr {
      border: none;
      border-top: 2px solid rgba(#0D0D0D, 0.1);
      margin: 2rem 0;
    }
}
.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

.textEditor-Menu {
    margin-top: 15px;
}
.textEditor-Menu button[type="button"] {
    color: #1976d2;
    margin: 5px;
}
.textEditor-Menu button[type="button"]:first-child {
  margin-left: 0px;
}
.textEditor-Menu button[type="button"]:last-child {
  margin-right: 0px;
}
.textEditor-Menu button[type="button"].MuiButton-outlinedSuccess {
    border: 1px solid rgba(76, 175, 80, 0.5);
    color: #4CAF50;
}

.textEditor .helper-text {
  color: #F44335;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}