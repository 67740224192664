.new_message {
	animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		background-color: rgba(121, 184, 240, 0.13);
	}

	70% {
		background-color: #49a3f198;
	}

	100% {
		background-color: rgba(121, 184, 240, 0.13);
	}
}